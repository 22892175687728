// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    /* background-color: #110028;  */
    padding: 20px;
    margin-top: 15px;
    /* display: flex;
    justify-content: left;
    align-items: center; */
    position: fixed;
    padding: 0 10em;
    z-index: 999;
    /* border-bottom: solid 2px #3a2067 ; */
  }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px;
  }
  
  .title {
    color: white;
    font-size: 24px;
    font-family: Arial, sans-serif; /* Adjust the font-family as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Header/index.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,aAAa;IACb,gBAAgB;IAChB;;0BAEsB;IACtB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,uCAAuC;EACzC;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,YAAY,EAAE,gCAAgC;IAC9C,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,8BAA8B,EAAE,qCAAqC;EACvE","sourcesContent":[".header {\n    /* background-color: #110028;  */\n    padding: 20px;\n    margin-top: 15px;\n    /* display: flex;\n    justify-content: left;\n    align-items: center; */\n    position: fixed;\n    padding: 0 10em;\n    z-index: 999;\n    /* border-bottom: solid 2px #3a2067 ; */\n  }\n  \n  .header-content {\n    display: flex;\n    align-items: center;\n  }\n  \n  .logo {\n    height: 40px; /* Adjust the height as needed */\n    margin-right: 10px;\n  }\n  \n  .title {\n    color: white;\n    font-size: 24px;\n    font-family: Arial, sans-serif; /* Adjust the font-family as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
