import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "./index.scss";
// import { verifyUuid } from "../../Utils";
import * as MetaMaskSDK from "@metamask/sdk";
import { useNavigate, useParams } from "react-router-dom";
import { apiCallPost } from "../../Client/axios";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/loader/loader";
import { CopyIcon } from "../../Assets/icons";
const ENV_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export default function Home() {
  const navigate = useNavigate();
  const [sdk, setSdk] = useState(null);

  const [accounts, setAccounts] = useState(null);
  const [inAppBrowser, setInAPPBrowser] = useState(false);
  const { connectionId } = useParams();
  const [loader, setloader] = useState(false);
  const [provider, setprovider] = useState(null);
  const [chainId, setChainId] = useState(null);

  const alreadyProcessing = sdk && provider;

  useEffect(() => {
    if (connectionId) {
      (async () => {
        const url = process.env.REACT_APP_BACKEND_URL + "/validate-token";
        const body = {
          token: connectionId,
        };

        const fetchRes = await apiCallPost(url, body);
        if (fetchRes.error) {
          navigate("/error");
        }
      })();
    }
  }, [connectionId]);

  useEffect(() => {
    if (accounts && chainId == ENV_CHAIN_ID.toLocaleLowerCase()) {
      (async () => {
        setloader(true);
        const url = process.env.REACT_APP_BACKEND_URL + "/address";
        const body = {
          symbol: "5ire",
          address: accounts,
          token: connectionId,
        };
        const fetchRes = await apiCallPost(url, body);
        console.log("fetchRes :: ", fetchRes);
        if (fetchRes?.status === 200) {
          window.location.href = process.env.REACT_APP_BOT;
        } else {
          console.log("Error .............");
          navigate("/error");
        }
        setloader(false);
      })();
    }
  }, [accounts, chainId]);
  const getSdk = () => {
    try {
      const sdk_ = new MetaMaskSDK.MetaMaskSDK({
        dappMetadata: {
          name: "Gaming App",
          url: window.location.host,
        },
        logging: {
          sdk: false,
        },
      });
      setSdk(sdk_);
    } catch (error) {
      console.error(error);
    }
  };

  const clearCache = () => {
    document.cookie.split(";").forEach(function (c) {
      document.cookie =
        c.trim().split("=")[0] +
        "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    });
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  };

  useEffect(() => {
    clearCache();
    getSdk();
  }, []);

  async function connect() {
    try {
      const provider_ = await sdk?.getProvider();
      if (provider_) {
        setprovider(provider_);
        const accounts_ = await provider_.request({
          method: "eth_requestAccounts",
        });

        const currentChainId = await provider_.request({
          method: "eth_chainId",
        });

        setChainId(currentChainId);
        setAccounts(accounts_[0]);
      } else {
        getSdk();
      }
    } catch (error) {
      if (error.code === -32002) {
        toast.success(
          "Request for Wallet is already pending . Please open Metamask",
          { className: "warning" }
        );
      }
      console.log("request accounts ERR", error);
    }
  }

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      toast.error("Please connect to MetaMask.");
      setAccounts(null);
    } else if (accounts[0] !== accounts) {
      setAccounts(accounts[0]);
    }
  }

  function handleDisconnect(error) {
    toast.error("MetaMask disconnected");
    setAccounts(null);
  }
  const handleButtonClick = () => {
    window.location.href = process.env.REACT_APP_BOT;
    setTimeout(() => {
      window.close();
    }, 2000);
  };
  async function addNetwork() {
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: ENV_CHAIN_ID,
            chainName: process.env.REACT_APP_CHAIN_NAME,
            blockExplorerUrls: [process.env.REACT_APP_EXPLORER_URL],
            nativeCurrency: {
              symbol: process.env.REACT_APP_CHAIN_SYMBOL,
              decimals: Number(process.env.REACT_APP_CHAIN_DECIMALS ?? 18),
            },
            rpcUrls: [process.env.REACT_APP_RPC_URL],
          },
        ],
      });
      toast.success(
        process.env.REACT_APP_CHAIN_NAME + " network added successfully"
      );
      const currentChainId = await provider.request({
        method: "eth_chainId",
      });
      setChainId(currentChainId);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className="btn-container">
      <div className="in">
        <div className="steps_form">
          <ul>
            <li>
              <button className={`${accounts ? "done" : "active"}`}>
                Step 1
              </button>
            </li>
            <li>
              <button className={`${accounts ? "active" : ""}`}>Step 2</button>
            </li>
          </ul>
        </div>

        <center>
          {inAppBrowser ? (
            <>
              <div className="steps_form_content">
                <p className="content mb-4">
                  Please disable In-App Browser of Telegram to Connect Wallet.
                </p>
                <div className="content mb-4">
                  <p>
                    {" "}
                    <b>Note:</b> Here are the steps to disable In-App Browser
                  </p>
                  <ol className="mt-3 text-start">
                    <li>
                      {" "}
                      Click on the{" "}
                      <span>
                        3 dots (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 48 48"
                          style={{
                            transform: "translateY(-0.3rem)",
                          }}
                          id="three-dots"
                        >
                          <path
                            fill="#fff"
                            d="M24 15C26.4853 15 28.5 12.9853 28.5 10.5 28.5 8.01472 26.4853 6 24 6 21.5147 6 19.5 8.01472 19.5 10.5 19.5 12.9853 21.5147 15 24 15zM24 28.5C26.4853 28.5 28.5 26.4853 28.5 24 28.5 21.5147 26.4853 19.5 24 19.5 21.5147 19.5 19.5 21.5147 19.5 24 19.5 26.4853 21.5147 28.5 24 28.5zM24 42C26.4853 42 28.5 39.9853 28.5 37.5 28.5 35.0147 26.4853 33 24 33 21.5147 33 19.5 35.0147 19.5 37.5 19.5 39.9853 21.5147 42 24 42z"
                          ></path>
                        </svg>
                        )
                      </span>{" "}
                      on the top.
                    </li>
                    <li>
                      {" "}
                      Click on <span>Settings</span>.
                    </li>
                    <li>
                      Toggle off the <span>Enable In-App Browser</span>.
                    </li>
                    <li>
                      Close this window and click on <span>Wallet Connect</span>
                      .
                    </li>
                  </ol>
                </div>
              </div>
            </>
          ) : accounts ? (
            <div className="steps_form_content">
              {loader && <Loader />}
              <p className="content mb-4">
                You have successfully connected your MetaMask wallet.
              </p>
              <p className="content mb-4">Current Chain ID: {chainId}</p>
              {chainId != ENV_CHAIN_ID.toLocaleLowerCase() && (
                <Button onClick={addNetwork}>Add 5ire Network</Button>
              )}
              <div className="copy_area">
                <p>
                  {accounts.slice(0, 4)}
                  ...
                  {accounts.slice(accounts.length - 4)}
                </p>
                <Button>
                  <CopyIcon />
                </Button>
              </div>
              {chainId == ENV_CHAIN_ID.toLocaleLowerCase() && (
                <>
                  <Button
                    fluid
                    className="mt-4 p-0"
                    onClick={handleButtonClick}
                  >
                    Click here to redirect to the game.
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div className="steps_form_content">
              <p className="content mb-4">
                Click the button below to connect to MetaMask.
              </p>
              <div className="content mb-4">
                Click the button to add the "5ire Network" to MetaMask.
              </div>
              <div className="content note mb-4">
                <b>Note:</b> 1. If you're using a mobile device and aren't
                automatically getting popup to connect MetaMask, please clear
                your browser cache first and then try to connect. <br />
                2. If you're using a mobile device and aren't automatically
                redirected back to this screen after logging into MetaMask,
                please return to this screen to complete all the steps.
              </div>
              <div className="action_btn">
                {alreadyProcessing ? (
                  <Button disabled>Connecting...</Button>
                ) : (
                  <Button onClick={connect}>Connect Wallet</Button>
                )}
                <Button
                  onClick={addNetwork}
                  disabled={chainId != ENV_CHAIN_ID.toLocaleLowerCase()}
                >
                  Add 5ire Network
                </Button>
              </div>
            </div>
          )}
        </center>
      </div>
    </div>
  );
}
