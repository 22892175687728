import React from 'react';
import './index.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <span>5ire Explorer 2.10.5</span>
        <span>© 5ire Copyright 2024. All rights reserved.</span>
        <span>Privacy Policy</span>
      </div>
    </footer>
  );
};

export default Footer;
