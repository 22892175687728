// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    /* background-color: #10131A;  */
    padding: 10px 0;
    text-align: center;
    color: #A0A4A8;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  
  .footer-content {
    display: flex;
    justify-content:center;
    /* max-width:  570px; */
    /* background-color: ; */
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .footer-content span {
    
    margin: 0  15px;
    /* font-size: 14px; */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Footer/index.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,SAAS;IACT,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,wBAAwB;IACxB,cAAc;IACd,eAAe;EACjB;;EAEA;;IAEE,eAAe;IACf,qBAAqB;EACvB","sourcesContent":[".footer {\n    /* background-color: #10131A;  */\n    padding: 10px 0;\n    text-align: center;\n    color: #A0A4A8;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    margin-bottom: 30px;\n  }\n  \n  .footer-content {\n    display: flex;\n    justify-content:center;\n    /* max-width:  570px; */\n    /* background-color: ; */\n    margin: 0 auto;\n    padding: 0 15px;\n  }\n  \n  .footer-content span {\n    \n    margin: 0  15px;\n    /* font-size: 14px; */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
