import "./App.css";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter basename="/">
      <Toaster />
      <Header />
      <Routes>
        <Route
          path="/connect-wallet/:connectionId"
          errorElement={
            <center>
              <h1
                style={{
                  color: "white",
                  fontSize: "16px",
                  display: "flex",
                  height: "100vh",
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Something Went Wrong !
                <a href={process.env.REACT_APP_BOT}> Go home</a>
              </h1>
            </center>
          }
          element={<Home />}
        />
        <Route
          path="/error"
          element={
            <center>
              <h1
                style={{
                  color: "white",
                  fontSize: "16px",
                  display: "flex",
                  height: "100vh",
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Invalid Id provided !
                <a href={process.env.REACT_APP_BOT}> Go home</a>
              </h1>
            </center>
          }
        />
        <Route
          path="*"
          element={
            <center>
              <h1>Page Not Found!</h1>
            </center>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
