// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dots_loader {
    min-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dots_loader .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white; /* This line will not work in pure CSS since \$white is a SCSS variable */
    border-radius: 50%;
    animation: bounce 1.5s infinite;
    -webkit-animation: bounce 1.5s infinite;
}

.dots_loader .dot:nth-child(1) {
    animation-delay: 0s;
}

.dots_loader .dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dots_loader .dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {
    0%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/loader/loader.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB,EAAE,wEAAwE;IACjG,kBAAkB;IAClB,+BAA+B;IAC/B,uCAAuC;AAC3C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI;;;QAGI,wBAAwB;IAC5B;;IAEA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["\n.dots_loader {\n    min-height: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.dots_loader .dot {\n    width: 10px;\n    height: 10px;\n    margin: 0 5px;\n    background-color: white; /* This line will not work in pure CSS since $white is a SCSS variable */\n    border-radius: 50%;\n    animation: bounce 1.5s infinite;\n    -webkit-animation: bounce 1.5s infinite;\n}\n\n.dots_loader .dot:nth-child(1) {\n    animation-delay: 0s;\n}\n\n.dots_loader .dot:nth-child(2) {\n    animation-delay: 0.3s;\n}\n\n.dots_loader .dot:nth-child(3) {\n    animation-delay: 0.6s;\n}\n\n@keyframes bounce {\n    0%,\n    80%,\n    100% {\n        transform: translateY(0);\n    }\n\n    40% {\n        transform: translateY(-10px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
